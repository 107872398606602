<template>
  <div>
    <!--NAVBAR-->
    <v-app-bar
        class="navigationAppbar"
    >
      <v-app-bar-title>{{$t('generic.licencePortal')}}</v-app-bar-title>
      <template v-slot:[`extension`]>
        <v-tabs>
          <v-tab :to="{name: 'dashboard'}" exact-path>{{$t('dlg.dashboard.dashboard')}}</v-tab>
          <v-tab :to="{name: 'contracts-overview'}">{{$t('dlg.contract.contracts')}}</v-tab>
          <v-tab :to="{name: 'invoices-overview'}">{{$t('dlg.invoice.invoices')}}</v-tab>
        </v-tabs>
      </template>
      <v-spacer/>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              class="me-2"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="showUserSettings = true">
            <v-list-item-icon>
              <v-icon>mdi-account-settings</v-icon>
            </v-list-item-icon>
            <v-list-item-title>##Benutzereinstellungen##</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showLanguageSelection = true">
            <v-list-item-icon>
              <v-icon>mdi-translate</v-icon>
            </v-list-item-icon>
            <v-list-item-title>##Sprache auswählen##</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>##Help##</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
          color="primary"
          @click="logout"
      >
        <v-icon left>mdi-logout</v-icon>
        {{$t('btns.logout')}}
      </v-btn>
    </v-app-bar>

    <!--NAVBAR POPUPS-->
    <v-dialog
        v-model="showLanguageSelection"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{$t('generic.labels.selectLanguage:')}}
          <v-spacer/>
          <v-btn
              icon
              @click="showLanguageSelection = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-select
              v-model="language"
              :items="languages"
              prepend-inner-icon="mdi-translate"
              dense
              hide-details="auto"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--PAGE CONTENT-->
    <router-view/>

    <!--FOOTER-->
    <v-footer class="footer">
      <v-row
          justify="center"
          no-gutters
      >
        <v-btn
            text
            target="_blank"
            href="https://www.liveconfig.com/de/ueber-uns/"
        >
          {{$t('btns.aboutUs')}}
        </v-btn>
        <v-btn
            text
            target="_blank"
            href="https://www.liveconfig.com/de/kontakt/"
        >
          {{$t('btns.contact')}}
        </v-btn>
        <v-btn
            text
            target="_blank"
            href="https://www.liveconfig.com/de/impressum/"
        >
          {{$t('btns.imprint')}}
        </v-btn>
        <v-col
            cols="12"
            class="text-center"
        >
          {{new Date().getFullYear()}} - <strong>LiveConfig</strong>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import MLC from "@/modules/MLC";

export default Vue.extend({
  name: 'mlc-base',
  data: function() {
    return {
      showUserSettings: false,
      showLanguageSelection: false,
      language: 'en',
    }
  },
  watch: {
    language(lang: string) {
      this.$i18n.locale = lang;
    },
    /*
    '$i18n.locale'(lang) {
      this.$vuetify.lang.current = lang;
      this.$vuetify.rtl = lang === "ar";
    }
     */
  },
  computed: {
    languages() {
      return [
        {value: 'de', text: this.$t('languages.de')},
        {value: 'en', text: this.$t('languages.en')}
      ]
    }
  },
  methods: {
    async logout() {
      try {
        await MLC.fetchJson('/api/v1/logout', {}, {method: 'DELETE'});
        //this.$router.push({name: 'login'});
        location.href=this.$MLC.prefix + '/login';
      } catch(error) {
        if (error instanceof Error) console.warn(error.message);
        location.href=this.$MLC.prefix + '/login';
      }
      //background: radial-gradient(circle, rgba(174,229,238,1) 0%, rgba(148,187,233,1) 100%);
      //background: linear-gradient(135deg, rgba(174,229,238,1) 0%, rgba(148,187,233,1) 100%);
      //background: linear-gradient(135deg, rgba(174,229,238,1) 0%, rgba(255,255,255,1) 36%);
      //background: linear-gradient(135deg, rgba(148,187,233,1) 0%, rgba(255,255,255,1) 36%);
    }
  }
});
</script>

<style>
.navigationAppbar {
  background: linear-gradient(135deg, rgba(174,229,238,1) 0%, rgba(255,255,255,1) 36%);
}

.footer {
  bottom: 0;
  position: fixed;
  width: 100%;
}
</style>