<template>
  <div>
    <h1 class="text-center lc-login-header">{{$t('generic.licencePortal')}}</h1>
    <v-layout class=" align-center justify-center">
      <v-card elevation="2" width="400px" class="lc-login">
        <v-card-title>{{$t('btns.login')}}</v-card-title>
        <mlc-form
            :form="frm"
            :state="state"
            :on-success="onSuccessfulLogin"
        >
          <v-card-text>
            <v-text-field
                id="login"
                v-model.trim="login"
                :label="$t('generic.labels.username:')"
                persistent-placeholder
                background-color="#EEEEEE"
                filled
                prepend-inner-icon="mdi-account"
                autocapitalize="off"
                spellcheck="false"
                autocorrect="off"
                hide-details="auto"
                class="mb-3"
                :rules="frm.check(true, { id: 'login' })"
                :error-messages="errors.login"
                @input="frm.reset('login')"
            />
            <v-text-field
                id="loginPassword"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :label="$t('generic.labels.password:')"
                persistent-placeholder
                background-color="#EEEEEE"
                filled
                prepend-inner-icon="mdi-key-variant"
                autocapitalize="off"
                spellcheck="false"
                autocorrect="off"
                hide-details="auto"
                :rules="frm.check(true, { id: 'loginPassword' })"
                :error-messages="errors.password"
                @input="frm.reset('password')"
            >
              <template v-slot:append>
                <v-icon @click="showPassword = !showPassword">{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
              </template>
            </v-text-field>
            <v-row class="align-center">
              <v-col cols="12" class="text-right">
                <v-btn
                    text
                    plain
                    small
                    @click="showResetPassword = true"
                >
                  {{$t('btns.resetPassword')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-btn
                      type="submit"
                      color="primary"
                  >
                    <v-icon left>mdi-login</v-icon>
                    {{$t('btns.login')}}
                  </v-btn>
                </v-col>
                <v-col cols="6" align="right">
                  <v-btn
                      @click="showRegister = true"
                  >
                    <v-icon left>mdi-account-plus</v-icon>
                    {{$t('btns.register')}}
                  </v-btn>
                  <v-btn @click="toDashboard">##ToDashboard##</v-btn>
                  <v-btn @click="testLogin">##TestLogin##</v-btn>
                  <v-btn @click="testGetContracts">##TestContracts##</v-btn>
                  <v-btn @click="testLogout">##TestLogout##</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-select
                    v-model="language"
                    :items="languages"
                    :label="$t('generic.labels.selectLanguage:')"
                    persistent-placeholder
                    background-color="#EEEEEE"
                    filled
                    prepend-inner-icon="mdi-translate"
                    dense
                    hide-details="auto"
                />
              </v-row>
            </v-container>
          </v-card-actions>
        </mlc-form>
      </v-card>
    </v-layout>
    <frm-dialog
        v-model="showResetPassword"
        :title="$t('dlg.password.resetTitle')"
        :text="$t('dlg.password.resetText')"
        :input-fields="{identifier: 'resetPassword', fields: [{type: 'text', vModel: {name: 'email', value: ''}, label: $t('dlg.contact.labels.email:'), required: true}]}"
        :on-create-frm="{url: '/app/reset', frmMode: 'Edit'}"
        submit-btn-name="btns.confirm"
        @cancel-dialog="showResetPassword = false"
    />
    <frm-dialog
        v-model="showRegister"
        :title="$t('dlg.register.registerTitle')"
        :input-fields="registerInputFields"
        :on-create-frm="{url: '/app/register', frmMode: 'New'}"
        submit-btn-name="btns.register"
        @cancel-dialog="showRegister = false"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import MlcForm, {Form, FormMode} from "@/components/MlcForm.vue";
import MLC from "@/modules/MLC";
import FrmDialog from "@/components/FrmDialog.vue";
import {MlcApi1} from "@/modules/MlcApi1";

export default Vue.extend({
  name: 'mlc-login-login',
  components: {MlcForm, FrmDialog},
  data: function() {
    return {
      errors: {} as { [ key: string] : string },
      state: {
        isDisabled: false,
        isValid: true,
        isProcessing: false
      },
      frm: null as null | Form,
      login: '',
      password: '',
      showPassword: false,
      language: 'en',
      showResetPassword: false,
      showRegister: false,
      registerInputFields: {
        identifier: 'register',
        fields: [
          {type: 'text', vModel: {name: 'username', value: ''}, label: this.$t('generic.labels.username:'), required: true},
          {type: 'password', vModel: {name: 'password', value: 'TEST__START', show: false}, label: this.$t('generic.labels.password:'), required: true},
          {type: 'text', vModel: {name: 'firstname', value: ''}, label: this.$t('dlg.contact.labels.firstname:'), required: true},
          {type: 'text', vModel: {name: 'lastname', value: ''}, label: this.$t('dlg.contact.labels.lastname:'), required: true},
          {type: 'text', vModel: {name: 'email', value: ''}, label: this.$t('dlg.contact.labels.email:'), required: true}
        ]
      }
    }
  },
  watch: {
    language(lang: string) {
      this.$i18n.locale = lang;
    },
    /*
    '$i18n.locale'(lang) {
      this.$vuetify.lang.current = lang;
      this.$vuetify.rtl = lang === "ar";
    }
     */
  },
  computed: {
    languages() {
      return [
        {value: 'de', text: this.$t('languages.de')},
        {value: 'en', text: this.$t('languages.en')}
      ]
    }
  },
  created() {
    this.frm = new Form({
      url: '/api/v1/login',
      data: this,
      mode: FormMode.New,
      serialize: () => {
        console.log('serialize called');
        return {
          login: this.login,
          password: this.password
        } as { [key: string]: string }
      }
    });
  },
  methods: {
    async suggestPassword() {
      this.showPassword = true;
      this.password = await MLC.suggestPassword();
    },
    onSuccessfulLogin(response: MlcApi1.ApiResponse) {
      console.log(response);
      this.$router.push({name: 'dashboard'});
    },
    toDashboard() {
      this.$router.push({name: 'dashboard'});
    },
     async testLogin() {
      //const response = await MLC.fetchJson('https://dev.my.liveconfig.com/api/v1/login', {login: 'shopuser', password: 'PaSsWoRd'}, {method: 'POST'});
      //console.log(response);
      document.cookie = 'session=s38tgcm4ro8m55ru7akknogg7s';
    },
    async testGetContracts() {
      const response = await MLC.fetchJson('https://dev.my.liveconfig.com/api/v1/contracts', {}, {method: 'GET'});
      console.log(response);
    },
    async testLogout() {
      const response = await MLC.fetchJson('https://dev.my.liveconfig.com/api/v1/logout', {}, {method: 'DELETE'});
      console.log(response);
    }
  }
});
</script>

<style>
.lc-login-header {
  font-size:6em;
  font-weight:bold;
  color:#c9c9c9;
  text-shadow: #000 0 0 30px;
}
</style>